import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { FaMicrosoft } from "react-icons/fa";
import { StaticImage } from "gatsby-plugin-image";

const RollerBlinds = () => (
  <React.Fragment>
    <Helmet>
      <title>Roller Blinds | Melbourne | Brisbane | Birk</title>
      <meta
        property="og:title"
        content="Roller Blinds | Melbourne | Brisbane | Birk"
      />
      <meta
        name="keywords"
        content="Roller Blinds, Roman Blinds, Curtains, Shutters, Venetians, External Range, Automation, Honeycomb Blinds"
      />
      <meta
        name="description"
        content="Birk offers range of quality Roller Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta
        property="og:description"
        content="Birk offers range of quality Roller Blinds. We are operated in Melbourne and Brisbane"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content="http://birk.itechnotion.com/roller-blinds"
      />
      <link rel="canonical" href="http://birk.itechnotion.com/roller-blinds" />
    </Helmet>
    <Header />
    <section className="product-section">
      <div className="container-fluid all-section">
        <div className="row">
          <div className="col-md-4 col-lg-3">
            <div className="heading">
              <h2>Roller Blinds</h2>
            </div>
            <div className="product-menu">
              <ul>
                <li className="mb-2">
                  <Link to="/roller-blinds">
                    <span className="font-weight-bold">Roller Blinds</span>
                  </Link>
                </li>
                <li className="mb-2">
                  <Link to="/roman-blinds">Roman Blinds</Link>
                </li>
                <li className="mb-2">
                  <Link to="/curtains">Curtains</Link>
                </li>
                <li className="mb-2">
                  <Link to="/shutters">Shutters</Link>
                </li>
                <li className="mb-2">
                  <Link to="/venetians">Venetians</Link>
                </li>
                <li className="mb-2">
                  <Link to="/external-range">External Range</Link>
                </li>
                <li className="mb-2">
                  <Link to="/automation">Automation</Link>
                </li>
                <li className="mb-2">
                  <Link to="/honeycomb">Honeycomb</Link>
                </li>
              </ul>
              <div className="links-list sidebar-links">
                <Link
                  to="/gallery"
                  className="links-list-item"
                  // state={{ modal: "roller" }}
                >
                  <FaMicrosoft />
                  &nbsp; Go to gallery
                </Link>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-lg-9 product-item mt-2">
            <div className="col-12 col-md-12 mb-4">
              <div className="pro-start-content">
                <div className="product-inner">
                  <p className="text-justify">
                    Birk roller blinds are the quiet achievers of our range. In
                    neutral or muted tones they don’t demand attention but
                    rather blend beautifully in light-filled rooms at home or
                    work. Don’t be fooled by their unassuming demeanour, quietly
                    in the background they’re helping to keep your place
                    insulated and, in turn, energy efficient.
                  </p>
                  <p className="text-justify">
                    When teamed in twos, the Birk dual system has the added
                    advantage of offering both daytime and night time privacy.
                    At work, protect your privacy in an overlooked boardroom. At
                    home, turn day to night with the flick of a switch – ideal
                    for bub’s bedroom.
                  </p>
                  <p className="text-justify">
                    There are many things that set Birk roller blinds apart from
                    its competitors. Birk utilises only stainless steel chains,
                    heavy duty aluminium tubes, colour coded components and
                    unique ‘patented’ tear drop bottom rail, adding an elegant
                    finish to your blinds.
                  </p>
                  <p className="text-justify mb-3">
                    Your Birk roller blinds can be customised in a range of
                    affordable and fashionable fabrics. Read more about our
                    Essentials Range and Classic Range
                  </p>
                  <p>
                    For even greater choice, elevate your interiors by choosing
                    from our Supreme Range
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-sm-12 mt-2 mb-4">
              <div className="d-flex flex-wrap">
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roller-icon1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Single
                    <br />
                    Roller
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roller-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Dual
                    <br />
                    Roller
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roller-icon3.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Cassette
                    <br />
                    Side Channel
                  </p>
                </div>
                <div className="mb-2 mr-3">
                  <StaticImage
                    src="../images/roman-icon2.png"
                    alt=""
                    className="img-fluid"
                  />
                  <p className="text-center font_style">
                    Motorisation
                    <br />
                  </p>
                </div>
              </div>
            </div>
            <div className="why-list why-list2 ml-4">
              <ul>
                <li>Low maintenance & simple operation</li>
                <li>Adds insulation</li>
                <li>Choose from sheer or blockout fabric</li>
              </ul>
            </div>
            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/broller1.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/broller2.png"
                alt=""
                className="img-fluid"
              />
            </div>

            <div className="col-12 col-md-12 mb-4">
              <StaticImage
                src="../images/broller3.png"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <Footer />
  </React.Fragment>
);
export default RollerBlinds;
